<template>
  <el-dialog
    v-bind="{ ...$attrs, ...props }"
    class="ui-dialog"
    :style="styles"
    :close-icon="CloseIcon"
  >
    <template v-if="$slots.header || !!props.subtitle || !!props.title" #header>
      <Text
        v-if="!!props.title && !$slots.header"
        variant="h5"
        color="textPrimary"
      >
        {{ props.title }}
      </Text>
      <Text
        v-if="!!props.subtitle && !$slots.header"
        variant="body4"
        color="textTertiary"
        component="p"
      >
        {{ props.subtitle }}
      </Text>
      <slot name="header"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { dialogProps } from 'element-plus'
import CloseIcon from '@base/assets/img/svg/icon-close.svg'
import Text from '@base/components/ui/text'

const props = defineProps({
  ...dialogProps,
  /*
   * Override ElDialog prop with default true
   */
  destroyOnClose: {
    type: Boolean,
    default: true,
  },
  /*
   * Override ElDialog prop with default true
   */
  center: {
    type: Boolean,
    default: true,
  },
  /*
   * Override ElDialog prop with default true
   */
  alignCenter: {
    type: Boolean,
    default: true,
  },
  /*
   * Custom prop for subtitle in the dialog header under the title
   */
  subtitle: {
    type: String,
    default: '',
  },
  /*
   * Custom prop for gap between dialog sections
   */
  gap: {
    type: Number,
    default: 20,
  },
  lockScroll: {
    type: Boolean,
    default: true,
  },
})

const styles = computed(() => ({
  '--ui-dialog-gap': `${props.gap}px`,
}))
</script>
<!-- eslint-disable vue/enforce-style-attribute -->
<style lang="postcss">
.ui-dialog.el-dialog {
  --el-dialog-bg-color: var(--brand-color-base-secondary);
  --el-dialog-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  --el-dialog-title-font-size: 1.125rem; /* 18px */
  --el-dialog-content-font-size: 0.875rem; /* 14px */
  --el-dialog-font-line-height: 1.5rem; /* 24px */
  --el-dialog-padding-primary: 1.5rem; /* 24px */
  --el-dialog-border-radius: 1.5rem; /* 24px */

  --ui-dialog-close-icon-size: 1.5rem; /* 24px */
  --ui-dialog-title-color: var(--brand-color-text-primary);
  --ui-dialog-content-color: var(--brand-color-text-secondary);

  display: flex;
  flex-direction: column;
  gap: var(--ui-dialog-gap);
  padding-top: 2.25rem; /* 36px */
  font-family: var(--font-family-secondary);
  background: var(--el-dialog-bg-color);
  border-radius: var(--el-dialog-border-radius);
  /* stylelint-disable-next-line order/properties-order */
  max-height: calc(100dvh - 2rem);

  & .el-dialog__header {
    padding: 0;
    margin-right: 0;
    color: var(--ui-dialog-content-color);
    white-space: break-spaces;
  }
  & .el-dialog__title {
    font-weight: 600;
    color: var(--ui-dialog-title-color);
  }
  & .el-dialog__headerbtn {
    top: var(--el-dialog-padding-primary);
    right: var(--el-dialog-padding-primary);
    width: var(--ui-dialog-close-icon-size);
    height: var(--ui-dialog-close-icon-size);

    color: var(--brand-color-text-tertiary);
    &:hover {
      color: var(--brand-color-text-secondary);
    }
    &:active,
    &:focus-visible {
      color: var(--brand-color-text-primary);
    }
    & .el-dialog__close {
      font-size: var(--ui-dialog-close-icon-size);
      color: inherit;
    }
  }
  & .el-dialog__body {
    height: 100%;
    padding: 0;
    overflow-y: auto;
    font-family: var(--font-family-primary);
    font-size: var(--el-dialog-content-font-size);
    line-height: 1.125rem; /* 18px */
    color: var(--ui-dialog-content-color);
  }
  & .el-dialog__footer {
    padding: 0;
    color: var(--ui-dialog-content-color);
  }
}
</style>
